var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title"},[(_vm.slug)?_c('div',{staticClass:"img"},[_c('responsive-image',{attrs:{"urls":{
        webp: [
          { size: 640, url: require(("@/assets/img/heroes/" + _vm.slug + "_640.webp")) },
          { size: 960, url: require(("@/assets/img/heroes/" + _vm.slug + "_960.webp")) },
          { size: 1280, url: require(("@/assets/img/heroes/" + _vm.slug + "_1280.webp")) },
          { size: 1920, url: require(("@/assets/img/heroes/" + _vm.slug + "_1920.webp")) },
          { size: 3000, url: require(("@/assets/img/heroes/" + _vm.slug + "_3000.webp")) } ],
        png: [
          { size: 640, url: require(("@/assets/img/heroes/" + _vm.slug + "_640.png")) },
          { size: 960, url: require(("@/assets/img/heroes/" + _vm.slug + "_960.png")) },
          { size: 1280, url: require(("@/assets/img/heroes/" + _vm.slug + "_1280.png")) },
          { size: 1920, url: require(("@/assets/img/heroes/" + _vm.slug + "_1920.png")) },
          { size: 3000, url: require(("@/assets/img/heroes/" + _vm.slug + "_3000.png")) } ],
      },"sizes":"100vw","alt":_vm.alt,"css":("object-position: " + _vm.imgObjectPosition + ";")}})],1):_vm._e(),_c('h1',{staticClass:"title"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }