<template>
  <div class="page-title">
    <div v-if="slug" class="img">
      <responsive-image
        :urls="{
          webp: [
            { size: 640, url: require(`@/assets/img/heroes/${slug}_640.webp`) },
            { size: 960, url: require(`@/assets/img/heroes/${slug}_960.webp`) },
            { size: 1280, url: require(`@/assets/img/heroes/${slug}_1280.webp`) },
            { size: 1920, url: require(`@/assets/img/heroes/${slug}_1920.webp`) },
            { size: 3000, url: require(`@/assets/img/heroes/${slug}_3000.webp`) },
          ],
          png: [
            { size: 640, url: require(`@/assets/img/heroes/${slug}_640.png`) },
            { size: 960, url: require(`@/assets/img/heroes/${slug}_960.png`) },
            { size: 1280, url: require(`@/assets/img/heroes/${slug}_1280.png`) },
            { size: 1920, url: require(`@/assets/img/heroes/${slug}_1920.png`) },
            { size: 3000, url: require(`@/assets/img/heroes/${slug}_3000.png`) },
          ],
        }"
        sizes="100vw"
        :alt="alt"
        :css="`object-position: ${imgObjectPosition};`"
      ></responsive-image>
    </div>
    <h1 class="title"><slot></slot></h1>
  </div>
</template>

<script>
import ResponsiveImage from '@/components/ResponsiveImage'

export default {
  name: 'PageTitle',
  components: {
    ResponsiveImage,
  },
  props: {
    slug: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: '',
    },
    imgObjectPosition: {
      type: String,
      default: 'center',
    },
  },
}
</script>

<style scoped>
.page-title {
  position: relative;
  height: min(480px, 50vh, 70vw);
}

.img {
  position: fixed;
  width: 100%;
  padding-bottom: min(480px, 50vh, 70vw);
  background: var(--col-beige-medium);
  z-index: -1;
}

.img >>> img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  object-fit: cover;
}

h1 {
  position: absolute;
  width: var(--text-width);
  top: calc(50% + var(--header-height) / 2);
  left: 50%;
  font-family: var(--font-serif);
  font-size: min(64px, 12px + 6vmin);
  letter-spacing: var(--tracking-serif);
  line-height: 1.2em;
  text-align: center;
  transform: translate(-50%, -50%);
}
</style>
