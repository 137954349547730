<template>
  <div class="supervision-und-coaching">
    <page-title slug="supervision" alt="Supervision und Coaching">
      Supervision und Coaching
    </page-title>
    <v-page>
      <h2 class="article-title">PSYCHOTHERAPEUTISCHES COACHING SOWIE SUPERVISION</h2>
      <article class="text hyphenate" v-html="supervisionUndCoaching.supervision"></article>

      <h2 class="article-title">BUSINESSCOACHING</h2>
      <article class="text hyphenate" v-html="supervisionUndCoaching.businesscoaching"></article>

      <div class="content-img">
        <responsive-image
          :urls="{
            webp: [
              { size: 640, url: require('@/assets/img/supervision/supervision_640.webp') },
              { size: 960, url: require('@/assets/img/supervision/supervision_960.webp') },
              { size: 1280, url: require('@/assets/img/supervision/supervision_1280.webp') },
              { size: 1920, url: require('@/assets/img/supervision/supervision_1920.webp') },
            ],
            jpg: [
              { size: 640, url: require('@/assets/img/supervision/supervision_640.jpg') },
              { size: 960, url: require('@/assets/img/supervision/supervision_960.jpg') },
              { size: 1280, url: require('@/assets/img/supervision/supervision_1280.jpg') },
              { size: 1920, url: require('@/assets/img/supervision/supervision_1920.jpg') },
            ],
          }"
          sizes="(min-width: 1280px) min(960px, min(calc(100vw - 128px), 1280px)), (min-width: 480px) min(960px, max(80vw, calc(100vw - 128px))), 100vw"
          alt="Supervision"
        ></responsive-image>
      </div>

      <h2 class="article-title">WORK-LIFE-BALANCE UND BURNOUT-PRÄVENTION</h2>
      <article class="text hyphenate" v-html="supervisionUndCoaching.workLifeBalance"></article>

      <h2 class="article-title">VORBEREITUNG AUF BEWERBUNGS- UND PRÜFUNGSSITUATIONEN</h2>
      <article class="text hyphenate" v-html="supervisionUndCoaching.vorbereitung"></article>
    </v-page>
  </div>
</template>

<script>
import axios from 'axios'

import PageTitle from '@/components/PageTitle'
import VPage from '@/components/Page'

import ResponsiveImage from '@/components/ResponsiveImage'

export default {
  name: 'SupervisionUndCoaching',
  components: {
    PageTitle,
    VPage,
    ResponsiveImage,
  },
  data() {
    return {
      supervisionUndCoaching: {},
    }
  },
  async beforeRouteEnter(to, from, next) {
    const res = await axios.get('/supervision-und-coaching')
    next(vm => (vm.supervisionUndCoaching = res.data.data))
  },
}
</script>
