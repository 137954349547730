var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"supervision-und-coaching"},[_c('page-title',{attrs:{"slug":"supervision","alt":"Supervision und Coaching"}},[_vm._v(" Supervision und Coaching ")]),_c('v-page',[_c('h2',{staticClass:"article-title"},[_vm._v("PSYCHOTHERAPEUTISCHES COACHING SOWIE SUPERVISION")]),_c('article',{staticClass:"text hyphenate",domProps:{"innerHTML":_vm._s(_vm.supervisionUndCoaching.supervision)}}),_c('h2',{staticClass:"article-title"},[_vm._v("BUSINESSCOACHING")]),_c('article',{staticClass:"text hyphenate",domProps:{"innerHTML":_vm._s(_vm.supervisionUndCoaching.businesscoaching)}}),_c('div',{staticClass:"content-img"},[_c('responsive-image',{attrs:{"urls":{
          webp: [
            { size: 640, url: require('@/assets/img/supervision/supervision_640.webp') },
            { size: 960, url: require('@/assets/img/supervision/supervision_960.webp') },
            { size: 1280, url: require('@/assets/img/supervision/supervision_1280.webp') },
            { size: 1920, url: require('@/assets/img/supervision/supervision_1920.webp') } ],
          jpg: [
            { size: 640, url: require('@/assets/img/supervision/supervision_640.jpg') },
            { size: 960, url: require('@/assets/img/supervision/supervision_960.jpg') },
            { size: 1280, url: require('@/assets/img/supervision/supervision_1280.jpg') },
            { size: 1920, url: require('@/assets/img/supervision/supervision_1920.jpg') } ],
        },"sizes":"(min-width: 1280px) min(960px, min(calc(100vw - 128px), 1280px)), (min-width: 480px) min(960px, max(80vw, calc(100vw - 128px))), 100vw","alt":"Supervision"}})],1),_c('h2',{staticClass:"article-title"},[_vm._v("WORK-LIFE-BALANCE UND BURNOUT-PRÄVENTION")]),_c('article',{staticClass:"text hyphenate",domProps:{"innerHTML":_vm._s(_vm.supervisionUndCoaching.workLifeBalance)}}),_c('h2',{staticClass:"article-title"},[_vm._v("VORBEREITUNG AUF BEWERBUNGS- UND PRÜFUNGSSITUATIONEN")]),_c('article',{staticClass:"text hyphenate",domProps:{"innerHTML":_vm._s(_vm.supervisionUndCoaching.vorbereitung)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }